











import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalAcademyRequestContents extends Mixins(ModalBaseMethod) {
  private requestContents: { [key: string]: string } = {
    before: '',
    after: '',
  }
  private done = false

  public async loadRequestContent(id: number) {
    console.debug(id)
    if (this.done) {
      this.show()
      return
    }
    await Vue.prototype.$http.httpWithToken
      .get(`/requests/${id}}`)
      .then((res: any) => {
        this.requestContents.before = res.data.beforeBranchName
        this.requestContents.after = res.data.afterBranchName
        this.show()
        this.done = true
      })
      .catch((error: any) => {
        this.requestContents = { before: '', after: '' }
        alert('エラーが発生しました')
      })
  }
}
