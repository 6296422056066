



























import { Component, Vue, Ref } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalAcademyRequestContents from '@/components/organisms/ModalAcademyRequestContents.vue'
import ModalAcademyRequestService from '@/components/organisms/ModalAcademyRequestService.vue'
import moment from 'moment'
import { GetRequestsResponseType } from '@/models/api/requests'

// 承認ステータス
const APPROVAL_STATUS = {
  NOT_YET: 1,
  APPROVAL: 2,
  REMAND: 3,
  PULL_BACK: 4,
}

// 申請種別
const REQUEST_TYPE = {
  ADD_INDIVIDUAL: 1,
  REMOVE_INDIVIDUAL: 2,
  BULK: 3,
  ADD_BRANCH: 4,
  REMOVE_BRANCH: 5,
  EDIT_BRANCH: 6,
}

@Component({
  components: {
    ButtonBase,
    InputWithLabel,
    SelectBase,
    TableBase,
    ModalAcademyRequestContents,
    ModalAcademyRequestService,
  },
})
export default class Request extends Vue {
  @Ref() modalAcademyRequestContents!: ModalAcademyRequestContents
  @Ref() modalAcademyRequestService!: ModalAcademyRequestService

  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId

  private branchCode = ''
  private branchName = ''

  private currentPage = 1
  private requestTableBasePerPage = 30
  private count = 0

  private statusOptionDatas = [
    { text: '未選択', value: '1' },
    { text: '処理済', value: '2' },
    { text: '未処理', value: '3' },
  ]
  private statusSelectedData: string = this.statusOptionDatas[0].value

  private requestTableBaseItems: object[] = []

  private requestTableBaseFields = [
    { key: 'branchCode', label: '教室コード' },
    { key: 'branchName', label: '教室名' },
    { key: 'requestDate', label: '申請日時' },
    { key: 'requestService', label: '申請サービス' },
    { key: 'requestSubject', label: '申請教科' },
    { key: 'requestContent', label: '申請内容' },
    { key: 'approve', label: '承認' },
    { key: 'approveMember', label: '対応者' },
    { key: 'approveDate', label: '対応日時' },
  ]

  private requestTableBaseButtons = ['approve']
  private requestTableBaseLinks = ['requestService']

  /**
   * 教室名変更内容確認
   */
  private showRequestContents(id: number): void {
    this.modalAcademyRequestContents.loadRequestContent(id)
  }

  /**
   * 一括申請内容確認
   */
  private showRequestService(id: number): void {
    this.modalAcademyRequestService.loadRequestService(id)
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.currentPage = page
    this.loadRequests()
  }

  /**
   * 申請一覧を取得する
   */
  private async loadRequests() {
    const params = [
      `academyId=${this.academyId}`,
      `status=${this.statusSelectedData}`,
      `limit=${this.requestTableBasePerPage}`,
      `offset=${this.requestTableBasePerPage * (this.currentPage - 1)}`,
      'sortKey=created_at',
      'order=desc',
    ]

    if (this.branchCode) params.push(`branchCode=${this.branchCode}`)
    if (this.branchName) params.push(`branchName=${this.branchName}`)
    const { data }: { data: GetRequestsResponseType } = await Vue.prototype.$http.httpWithToken.get(
      `/requests?${params.join('&')}`
    )
    this.requestTableBaseItems = data.requests.map((request) => {
      const { id, requestType, approvalStatus, serviceName } = request
      // ■申請種別を確認
      // 1,2 はサービス系の申請(一括は除く)
      const isServiceRequest = [REQUEST_TYPE.ADD_INDIVIDUAL, REQUEST_TYPE.REMOVE_INDIVIDUAL].includes(requestType)
      // 1,4 は追加 / 2,5 は削除
      const requestContent = [REQUEST_TYPE.ADD_INDIVIDUAL, REQUEST_TYPE.ADD_BRANCH].includes(requestType)
        ? '追加'
        : [REQUEST_TYPE.REMOVE_INDIVIDUAL, REQUEST_TYPE.REMOVE_BRANCH].includes(requestType)
        ? '削除'
        : '-'
      // 処理済みどうかを確認
      const isConfirmed = approvalStatus === APPROVAL_STATUS.NOT_YET ? false : true

      return {
        id,
        academyCode: request.academyCode,
        academyName: request.academyName,
        branchCode: requestType === REQUEST_TYPE.BULK ? '-' : request.branchCode,
        branchName: requestType === REQUEST_TYPE.BULK ? '-' : request.branchName,
        requestDate: moment(request.createdAt).format('YYYY/MM/DD HH:mm'),
        requestService: this.createRequestServiceInfo(requestType, isConfirmed, serviceName, id),
        requestSubject: isServiceRequest ? request.subjectName : '-',
        requestContent,
        approve: this.createApproveInfo(approvalStatus, id),
        approveMember: isConfirmed ? request.updateUserNickname : '',
        approveDate: isConfirmed ? moment(request.updatedAt).format('YYYY/MM/DD HH:mm') : '',
      }
    })
    this.count = data.count
  }

  /**
   * 「申請サービス」列表示に関する情報を生成
   */
  private createRequestServiceInfo(requestType: number, isConfirmed: boolean, serviceName: string, id: number) {
    const activeRequestTypes = [REQUEST_TYPE.BULK, REQUEST_TYPE.EDIT_BRANCH]
    const disabled = isConfirmed || !activeRequestTypes.includes(requestType)
    switch (requestType) {
      case REQUEST_TYPE.ADD_INDIVIDUAL:
      case REQUEST_TYPE.REMOVE_INDIVIDUAL:
        return { disabled, name: serviceName }
      case REQUEST_TYPE.BULK:
        return { disabled, onclick: this.showRequestService, variable: id, name: '一括申請' }
      case REQUEST_TYPE.ADD_BRANCH:
      case REQUEST_TYPE.REMOVE_BRANCH:
        return { disabled, name: '教室管理' }
      case REQUEST_TYPE.EDIT_BRANCH:
        return { disabled, onclick: this.showRequestContents, variable: id, name: '教室名変更' }
      default:
        return '-'
    }
  }

  /**
   * 「承認」列表示に関する情報を生成
   */
  private createApproveInfo(approvalStatus: number, requestId: number) {
    if (APPROVAL_STATUS.NOT_YET === approvalStatus) {
      return [
        {
          name: '引き戻し',
          onclick: () => {
            if (confirm('引き戻しを行います。')) this.pullBackRequest(requestId)
          },
        },
      ]
    } else {
      // 承認済の場合はステータスを表示
      const statusTexts = {
        [APPROVAL_STATUS.APPROVAL]: '承認',
        [APPROVAL_STATUS.REMAND]: '差し戻し',
        [APPROVAL_STATUS.PULL_BACK]: '引き戻し',
      }
      return [{ disabled: true, name: statusTexts[approvalStatus] }]
    }
  }

  /**
   * 申請引き戻しAPI実行
   */
  private async pullBackRequest(requestId: number) {
    await Vue.prototype.$http.httpWithToken
      .patch(`/requests/${requestId}`, { approvalStatus: APPROVAL_STATUS.PULL_BACK })
      .then(() => {
        alert('更新しました')
        this.loadRequests()
      })
      .catch((error: any) => {
        alert('エラーが発生しました。ページを更新して再度お確かめください。')
      })
  }

  private async mounted() {
    this.loadRequests()
  }
}
